.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1));
}
.grow {
  transition: all 0.3s ease-in-out;
}
.grow:hover {
  transform: scale(1.05);
  cursor: pointer;
}
