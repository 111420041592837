
.swiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #E6E6E6;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 100%;
}

.swiper-slide img {
  /* display: block;
  width: 80%;
  height: 100%;
  object-fit: cover; */
}
